import React, { useEffect } from 'react'
import { CertifyResponseParams } from '../interfaces/CertifyResponseParams'

export default function MobileCertificationWindow() {
  const handleCertification = async () => {
    const { IMP } = window
    const IAMPORT_ID = process.env.REACT_APP_IAMPORT_FRANCHISE_ID as string

    try {
      IMP?.init(IAMPORT_ID)
      IMP?.certification({
        pg: 'danal',
        merchant_uid: `merchant_${new Date().getTime()}`,
        popup: false,
        m_redirect_url: 'https://cert.nhhc.co.kr/success',
      }, (rsp: CertifyResponseParams) => {
        if (!rsp.success) {
          // @ts-ignore
          // eslint-disable-next-line no-undef
          appFnAuth.postMessage(`Error Code: ${rsp.error_code}\nError Message: ${rsp.error_msg}`)
        }
      })
    } catch (err) {
      // @ts-ignore
      // eslint-disable-next-line no-undef
      appFnAuth.postMessage('인증모듈 초기화 실패')
    }
  }

  useEffect(() => {
    handleCertification()
  }, [])

  return (
    <>
    </>
  )
}
