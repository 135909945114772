/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react'
import axios from 'axios'
import { useLocation } from 'react-router-dom'

export default function SuccessPage() {
  const { search } = useLocation()
  const queryUid = new URLSearchParams(search).get('imp_uid') as string
  const FIREBASE_URL = process.env.REACT_APP_FIREBASE_URL as string

  const sendMessage = (msg: string) => {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    appFnAuth.postMessage(msg)
  }

  const getCertificationsInfo = async (impUid: string) => {
    try {
      const body = {
        imp_uid: impUid,
      }
      await axios.post(`${FIREBASE_URL}/certification`, body, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        sendMessage(JSON.stringify(res.data.certificationsInfo))
      })
    } catch (err) {
      sendMessage('')
    }
  }

  useEffect(() => {
    getCertificationsInfo(queryUid)
  }, [queryUid])

  return (
    <>
    </>
  )
}
