import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import IntegratedCertificationWindow from './components/IntegratedCertificationWindow'
import MobileCertificationWindow from './components/MobileCertificationWindow'
import SuccessPage from './components/SuccessPage'

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<IntegratedCertificationWindow />} />
          <Route path="/mobile" element={<MobileCertificationWindow />} />
          <Route path="/success" element={<SuccessPage />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App
